import React from 'react';
import Logo from '../components/Logo';

const Home = () => {
  return <>
    <div className="centerBoth">
      <Logo />
    </div>
  </>;
};

export default Home;