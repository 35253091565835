
import React from 'react';
import { TextField, Box, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Squiggle from '../components/Squiggle';
import useBroadcast from '../liveswitch/useBroadcast';
import { ConnectionState } from '../liveswitch/common';

const { PUBLIC_URL } = process.env;
const py = 2;

const TalentStream = (props) => {
  const { program, talent } = props.match.params;
  const { state, previewRef } = useBroadcast(program, talent, talent);
  const { history } = props;

  return <div className="stream">
    <div className="squiggleBox">
      <Squiggle variant="red" />
      <div ref={previewRef} />
      {state.connectionState === ConnectionState.Ready &&
        <>
          <h1 className="liveNotice" >
            RECORDING - YOU ARE LIVE!
          </h1>
          <Box py={py}>
            <button
              className="customButton"
              onClick={() => {
                history.push(`/kasters/${program}/`);
              }}
            >
              <img src={`${PUBLIC_URL}/assets/live-button.png`} alt="Stop Broadcasting" />
            </button>
          </Box>
          <Box>
            STOP BROADCASTING
          </Box>
          <Box p={2}>
            <TextField
              id="shareable"
              variant="outlined"
              label="Watch Link"
              fullWidth
              value={`${window.location.origin}/join/${program}`}
              onFocus={e => {
                e.preventDefault();
                e.currentTarget.selectionStart = 0;
                e.currentTarget.selectionEnd = e.currentTarget.value.length;
                document.execCommand('copy');
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={e => {
                e.preventDefault();
                document.getElementById('shareable').focus();
              }}
            >
              Copy Watch Link
          </Button>
          </Box>
        </>
      }
    </div>
  </div>;
};

export default withRouter(TalentStream);