import { useReducer, useRef, useEffect, useCallback } from "react";
import { log, ConnectionState, openChannel } from './common';
import { useHistory } from "react-router-dom";
import liveswitch from 'fm.liveswitch';

type Kaster = string;

interface BroadcastState {
  connectionState: ConnectionState;
  peers: Kaster[];
}

export enum Actions {
  CONNECTING = 'CONNECTING',
  READY = 'READY',
  KASTER_LOGGED_IN = 'KASTER_LOGGED_IN',
  KASTER_LOGGED_OUT = 'KASTER_LOGGED_OUT',
};

const initialBroadcast: BroadcastState = {
  peers: [],
  connectionState: ConnectionState.Initial,
}

const broadcastReducer = (state: BroadcastState, action: any) => {
  const n = { ...state };
  log(action.type);
  switch (action.type) {
    case Actions.CONNECTING:
      n.connectionState = ConnectionState.Connecting;
      return n;
    case Actions.READY:
      n.connectionState = ConnectionState.Ready;
      return n;
    case Actions.KASTER_LOGGED_IN:
      if (!n.peers.includes(action.kaster)) {
        n.peers.push(action.kaster);
      }
      return n;

    case Actions.KASTER_LOGGED_OUT:
      const idx = n.peers.indexOf(action.kaster);
      if (idx > -1) {
        n.peers.splice(idx, 1);
      }
      return n;

    default:
      throw new Error();
  }
};

const useBroadcast = (program: string, user: string, deviceId: string) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(broadcastReducer, initialBroadcast);
  const previewRef = useRef<HTMLElement>(null);
  const layoutManagerRef = useRef<liveswitch.DomLayoutManager | null>();
  const connectionsRef = useRef<any>({});

  const closeConnections = useCallback(
    () => {
      const cons = connectionsRef.current;
      if (!cons) {
        return;
      }
      if (!cons.main) {
        return;
      }
      cons.main.close();
      cons.localMedia.stop();
      cons.localMedia.destroy();
      cons.channel.closeAll();
    },
    [connectionsRef]
  );

  history.listen((location) => {
    if (location.pathname !== `/stream/${program}/${user}`) {
      closeConnections();
    }
  });

  useEffect(
    () => {
      window.addEventListener('beforeunload', closeConnections);
      window.addEventListener('unload', closeConnections);
      return function cleanup() {
        window.removeEventListener('beforeunload', closeConnections);
        window.removeEventListener('unload', closeConnections);
      };
    },
    [closeConnections]
  );

  useEffect(
    () => {
      if (state.connectionState !== ConnectionState.Initial) {
        return;
      }
      dispatch({ type: Actions.CONNECTING });
      const audio = {
        sampleSize: 16,
        channelCount: 2,
        echoCancellation: false,
      };
      const localMedia = new liveswitch.LocalMedia(audio, null);
      const audioStream = new liveswitch.AudioStream(localMedia);

      log('before register()');
      (async function register() {
        log('during register()');
        try {
          log(`before openChannel(${user}, ${deviceId}, ${program})`);
          const { channel } = await openChannel(
            user,
            deviceId,
            program
          );
          log('after openChannel()');
          if (!channel) {
            log('No channel acquired, aborting');
            return;
          }
          log('before channel.addOnRemoteUpstreamConnectionOpen()');
          channel.addOnRemoteUpstreamConnectionOpen(async (rci) => {
            const userId = rci.getUserId();
            if (userId === user) {
              log('No need to listen to ourselves');
              return;
            }
            dispatch({
              type: Actions.KASTER_LOGGED_IN,
              kaster: userId,
              sessionId: rci.getId()
            });
            const remoteMedia = new liveswitch.RemoteMedia();
            const audioStream = new liveswitch.AudioStream(remoteMedia);
            const connection = channel.createSfuDownstreamConnection(
              rci,
              audioStream
            );

            connection.addOnStateChange(c => {
              if (c.getState() === liveswitch.ConnectionState.Closing
                || c.getState() === liveswitch.ConnectionState.Failing
              ) {
                dispatch({
                  type: Actions.KASTER_LOGGED_OUT,
                  kaster: userId,
                  sessionId: rci.getId()
                });
                if (layoutManagerRef.current) {
                  layoutManagerRef.current.removeRemoteView(remoteMedia.getId());
                }
              }
            });
            log('before connection.open()');
            await connection.open();
            log('after connection.open()');

          });
          const conn = channel.createSfuUpstreamConnection(audioStream);

          log('before conn.open()');
          await conn.open();
          log('after conn.open()');
          localMedia.start();
          dispatch({ type: Actions.READY });
          connectionsRef.current = {
            main: conn,
            localMedia,
            channel,
          };

        } catch (e) {

        }
      }());
    },
    [
      user,
      state.connectionState,
      deviceId,
      program,
    ]
  );

  useEffect(
    () => {
      if (previewRef.current) {
        layoutManagerRef.current = new liveswitch.DomLayoutManager(previewRef.current);
      }
    },
    [
      previewRef,
    ]
  );

  return {
    previewRef,
    state,
    closeConnections,
  };
};

export default useBroadcast;
