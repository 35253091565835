import React from 'react';
import Logo from '../components/Logo';

const Home = () => {
  return <>
    <div className="centerBoth">
      <div>
        <Logo />
        <div>
          We couldn't find what you were looking for.
        </div>
      </div>
    </div>
  </>;
};

export default Home;