import liveswitch from 'fm.liveswitch';

enum ConnectionState {
  Initial,
  Connecting,
  Ready,
};

const {
  REACT_APP_LS_GATEWAY,
  REACT_APP_LS_ID,
  REACT_APP_LS_SHARED_KEY,
} = process.env;

const makeClient = (userId: string, deviceId: string) => {
  liveswitch.Log.setLogLevel(liveswitch.LogLevel.Debug);
  liveswitch.Log.registerProvider(new liveswitch.ConsoleLogProvider(liveswitch.LogLevel.Debug));
  log(REACT_APP_LS_GATEWAY, REACT_APP_LS_ID, REACT_APP_LS_SHARED_KEY);

  return new liveswitch.Client(
    REACT_APP_LS_GATEWAY,
    REACT_APP_LS_ID,
    userId,
    deviceId,
    '',
    []
  );
};

const generateToken = (client: liveswitch.Client, program: string) => {
  return liveswitch.Token.generateClientRegisterToken(
    REACT_APP_LS_ID,
    client.getUserId(),
    client.getDeviceId(),
    client.getId(),
    client.getRoles(),
    [new liveswitch.ChannelClaim(program)],
    REACT_APP_LS_SHARED_KEY
  );
};

const openChannel = async (userId: string, deviceId: string, program: string) => {
  const client = makeClient(userId, deviceId);
  const token = generateToken(client, program);
  const channel = (await client.register(token)).pop();
  return { client, token, channel };
};

const workAroundiOSSafariSilenceBug = async () => {
  let localMedia: liveswitch.LocalMedia | null;
  if (liveswitch.Util.isSafari()) {
    localMedia = new liveswitch.LocalMedia(true, false);
    const lm = await localMedia.start();
    if (liveswitch.Util.isiOS()) {
      // Safari iOS needs this
      lm.setAudioMuted(true);
    }
    await lm.stop();
    if (localMedia !== null) {
      localMedia.destroy();
      localMedia = null;
    }
  }
};

const log = (message?: any, ...items: any) => {
  items.unshift(message);
  if (/^dev/.test(process.env.NODE_ENV)) {
    console.log.apply(console, items);
  }
};



export {
  log,
  ConnectionState,
  REACT_APP_LS_GATEWAY,
  REACT_APP_LS_ID,
  REACT_APP_LS_SHARED_KEY,
  makeClient,
  generateToken,
  openChannel,
  workAroundiOSSafariSilenceBug,
};

