import { useReducer, useRef, useEffect } from "react";
import { ConnectionState, openChannel, workAroundiOSSafariSilenceBug } from './common';
import useGetJson from '../useGetJson';
import liveswitch from 'fm.liveswitch';

type SessionId = string;

interface Kasters {
  [user: string]: SessionId[];
}

interface ListenToStreamState {
  joined: boolean;
  connectionState: ConnectionState;
  kasters: Kasters;
};

const initialKasters: ListenToStreamState = {
  joined: false,
  connectionState: ConnectionState.Initial,
  kasters: {},
};

export enum Actions {
  JOIN = 'JOIN',
  LEAVE = 'LEAVE',
  SET_KASTER_LIST = 'SET_KASTER_LIST',
  KASTER_LOGGED_IN = 'KASTER_LOGGED_IN',
  KASTER_LOGGED_OUT = 'KASTER_LOGGED_OUT',
  CONNECTING = 'CONNECTING',
  READY = 'READY',
};

const kastersReducer = (state: ListenToStreamState, action: any) => {
  const n = { ...state };
  switch (action.type) {
    case Actions.JOIN:
      n.joined = true;
      return n;

    case Actions.LEAVE:
      n.joined = false;
      return n;

    case Actions.SET_KASTER_LIST:
      Object.entries(action.kasters).forEach(([kaster]) => {
        n.kasters[kaster] = [];
      });
      return n;
    case Actions.KASTER_LOGGED_IN:
      if (!n.kasters.hasOwnProperty(action.kaster)) {
        throw new Error(`Kaster "${action.kaster}" is not valid.`);
      }
      if (!n.kasters[action.kaster].includes(action.sessionId)) {
        n.kasters[action.kaster].push(action.sessionId);
      }
      return n;
    case Actions.KASTER_LOGGED_OUT:
      const sessionIdIndex = n.kasters[action.kaster].indexOf(action.sessionId);
      if (sessionIdIndex > -1) {
        n.kasters[action.kaster].splice(sessionIdIndex, 1);
      }
      return n;
    case Actions.CONNECTING:
      n.connectionState = ConnectionState.Connecting;
      return n;
    case Actions.READY:
      n.connectionState = ConnectionState.Ready;
      return n;
    default:
      throw new Error();
  }
};

const useListenToStream = (program: string, user: string | 'rando', deviceId: string | undefined) => {
  const [state, dispatch] = useReducer(kastersReducer, initialKasters);
  const previewRef = useRef<HTMLElement>(null);
  const layoutManagerRef = useRef<liveswitch.DomLayoutManager | null>();
  const programData = useGetJson(`/stream_info/${program}.json`);

  useEffect(
    () => {
      if (!state.joined) {
        return;
      }
      if (programData.loading || programData.response === null) {
        return;
      }
      dispatch({
        type: Actions.SET_KASTER_LIST,
        kasters: programData.response.kasters
      });
    },
    [
      programData,
      state.joined,
    ]
  );

  useEffect(
    () => {
      if (!state.joined) {
        return;
      }
      if (programData.loading || programData.response === null) {
        return;
      }
      dispatch({ type: Actions.CONNECTING });
      (async function register() {
        try {
          const { channel } = await openChannel(
            user,
            deviceId || Math.round(Math.random() * 32000).toString(),
            program
          );
          if (!channel) {
            return;
          }
          workAroundiOSSafariSilenceBug();
          channel.addOnRemoteUpstreamConnectionOpen(async (rci) => {
            const userId = rci.getUserId();
            if (!programData.response.kasters.hasOwnProperty(userId)) {
              return;
            }
            dispatch({
              type: Actions.KASTER_LOGGED_IN,
              kaster: userId,
              sessionId: rci.getId()
            });
            const remoteMedia = new liveswitch.RemoteMedia();
            const audioStream = new liveswitch.AudioStream(remoteMedia);
            const connection = channel.createSfuDownstreamConnection(
              rci,
              audioStream
            );

            connection.addOnStateChange(c => {
              if (c.getState() === liveswitch.ConnectionState.Closing
                || c.getState() === liveswitch.ConnectionState.Failing
              ) {
                dispatch({
                  type: Actions.KASTER_LOGGED_OUT,
                  kaster: userId,
                  sessionId: rci.getId()
                });
                if (layoutManagerRef.current) {
                  layoutManagerRef.current.removeRemoteView(remoteMedia.getId());
                }
              }
            });
            await connection.open();

          });

        } catch (e) {

        }
        dispatch({ type: Actions.READY });
      }());
    },
    [
      programData,
      state.joined,
      user,
      deviceId,
      program,
    ]
  );

  useEffect(
    () => {
      if (!state.joined) {
        return;
      }
      if (previewRef.current) {
        layoutManagerRef.current = new liveswitch.DomLayoutManager(previewRef.current);
      }
    },
    [
      previewRef,
      state.joined,
    ]
  );

  return {
    previewRef,
    programData,
    state,
    leave: () => dispatch({type: Actions.LEAVE}),
    join: () => dispatch({type: Actions.JOIN}),
  };
};

export default useListenToStream;
