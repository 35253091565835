import React, { useContext } from 'react';
import { TextField, Box } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import App from '../App';
import useGetJson from '../useGetJson';
import Logo from '../components/Logo';
import Squiggle from '../components/Squiggle';

const { PUBLIC_URL } = process.env;
const py = 2;

const TalentLogin = ({ match, history }) => {
  const appState = useContext(App.State);
  const { control, register, handleSubmit, errors, getValues } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      talent: appState.broadcaster,
      password: appState.password,
    }
  });
  const program = match.params.program;
  const programData = useGetJson(`/stream_info/${program}.json`);

  const { kasters } = programData?.response || { kasters: {} };

  const startStream = (data) => {
    appState.setBroadcaster(data.talent);
    history.push(`/stream/${program}/${data.talent}`);
  };

  return <div>
    <div className="loading" style={{ display: programData.loading ? 'block' : 'none' }} />
    <div style={{ display: programData.loading ? 'none' : 'block' }}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(startStream)}
      >
        <div className="login">
          <div className="squiggleBox">
            <Squiggle variant="white" />
            <div className="textFields">
              <Box py={py}>

                <Controller
                  as={TextField}
                  control={control}
                  id="talent"
                  name="talent"
                  variant="filled"
                  label="Username"
                  fullWidth
                  autoFocus
                  error={!!errors.talent}
                  helperText={errors.talent && errors.talent.message}
                  inputRef={register({
                    required: true,
                    validate: {
                      pattern: v => /^[a-z0-9_]+$/i.test(v) || "Must contain only letters, numbers or underscores",
                      exists: v => kasters.hasOwnProperty(v) || 'Unknown username.',
                    },
                  })}
                />
              </Box>
              <Box py={py}>
                <Controller
                  as={TextField}
                  control={control}
                  id="password"
                  name="password"
                  type="password"
                  variant="filled"
                  error={!!errors.password}
                  fullWidth
                  label="Password"
                  helperText={errors.password && errors.password.message}
                  inputRef={register({
                    required: true,
                    validate: v => {
                      const t = String(getValues("talent"));
                      return v === kasters[t] || "Invalid password";
                    },
                  })}
                />
              </Box>
            </div>
          </div>
          <Box py={py}>
            <button
              className="customButton"
              type="submit"
            >
              <img src={`${PUBLIC_URL}/assets/live-button.png`} alt="Go Live" />
            </button>
          </Box>
          <Box>
            PRESS TO GO LIVE
          </Box>
          <div className="smallLogo">
            <Logo />
          </div>
        </div>
      </form>
    </div>
  </div>;
};

export default TalentLogin;