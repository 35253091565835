import React from 'react';

const { PUBLIC_URL } = process.env;

interface Props {
    variant: string | 'white';
}

const Squiggle = ({ variant }: Props) => {
    return <img className="squiggle" alt="squiggle" src={`${PUBLIC_URL}/assets/squiggle-${variant}.png`} />
};

export default Squiggle;