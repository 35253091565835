import React from 'react';
import { Link } from 'react-router-dom';

const { PUBLIC_URL } = process.env;

const Logo = () => {
    return <Link className="logo" to="/">
        <img src={`${PUBLIC_URL}/logo.png`} alt="SHADOWKAST" title="SHADOWKAST" />
    </Link>;
};

export default Logo;