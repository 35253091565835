import React from 'react';
import { AiFillPlayCircle, AiOutlinePauseCircle } from 'react-icons/ai';

interface Props {
    playing: boolean;
    onClick: (e: React.MouseEvent) => void;
}

const MediaButton = ({ playing, onClick }: Props) => {
    return <div className="mediaButton" onClick={onClick}>
        {playing ? <AiOutlinePauseCircle /> : <AiFillPlayCircle />}
    </div>
};

export default MediaButton;