import React from 'react';
import { TextField, Box, Button, CircularProgress } from '@material-ui/core';
import MediaButton from '../components/MediaButton';
import Logo from '../components/Logo';
import Squiggle from '../components/Squiggle';
import useListenToStream from '../liveswitch/useListenToStream';
import { ConnectionState } from '../liveswitch/common';

const Watch = (props) => {
  const { program } = props.match.params;
  const { previewRef, state, programData, leave, join } = useListenToStream(program);

  return <>
    {programData.loading &&
      <div>
        <Box
          display="flex"
          justifyContent="center"
          py={48}
        >
          <CircularProgress />
        </Box>
      </div>}
    {programData.loading === false && <>
      <div className="centerBoth">
        <div className="watchBox">
          {state.joined === false && <Logo />}
          {state.joined === true && <Squiggle variant="white" />}
          <MediaButton playing={state.joined} onClick={() => {
            if (state.joined) {
              leave();
            } else {
              join();
            }
          }}/>
          {state.joined === true && <>
            <div ref={previewRef} />
            <div className="watchInfo">
              Listening to {programData.response.name}
            </div>
            {state.connectionState === ConnectionState.Ready && <>
              <Box p={2}>
                <TextField
                  id="shareable"
                  variant="outlined"
                  label="Shareable Link"
                  fullWidth
                  value={window.location.href}
                  onFocus={e => {
                    e.preventDefault();
                    e.currentTarget.selectionStart = 0;
                    e.currentTarget.selectionEnd = e.currentTarget.value.length;
                    document.execCommand('copy');
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={e => {
                    e.preventDefault();
                    document.getElementById('shareable').focus();
                  }}
                >
                  Copy Shareable Link
                </Button>
              </Box>
            </>}
            <div className="help">
              Cant hear anything? <a href="/help.html">Here's help.</a>
            </div>
          </>}
        </div>
      </div>
    </>}
  </>;
};

export default Watch;