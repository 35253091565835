import "./App.scss";
import React, { useLayoutEffect, useState } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Home from './routes/Home';
import TalentLogin from './routes/TalentLogin';
import TalentStream from './routes/TalentStream';
import NotFound from './routes/NotFound';
import Watch from './routes/Watch';

const useCustomViewportProperties = () => {
  const [vh, setVh] = useState(0);
  const [vw, setVw] = useState(0);
  useLayoutEffect(
    () => {
      const handleResize = () => {
        const h = window.innerHeight * 0.01;
        setVh(h);
        document.documentElement.style.setProperty('--vh', `${h}px`);

        const w = window.innerWidth * 0.01;
        setVw(w);
        document.documentElement.style.setProperty('--vw', `${w}px`);
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      return function cleanup() {
        window.removeEventListener("resize", handleResize);
      };
    },
    []
  );
  return [vh, vw];
};

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#FFFFFF',
    },

    error: {
      main: '#FF0000',
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#474946',
        '&$focused': {
          backgroundColor: '#1E1F1D',
        },
        '&:hover': {
          backgroundColor: '#1E1F1D',
        },
        '&$error': {
          backgroundColor: '#474946',
        },
      },
    },
  },
});

const appState = {
  ...JSON.parse(localStorage.getItem('kast') || '{}'),
  source: null,
  setBroadcaster: (name: string) => {
    appState.broadcaster = name;
    appState.save();
  },
  save: () => {
    localStorage.setItem('kast', JSON.stringify(appState));
  },
};

const State = React.createContext(appState);

function App() {
  useCustomViewportProperties();
  return (
    <ThemeProvider theme={theme}>
      <State.Provider value={appState}>
        <BrowserRouter>
          <Switch>
            <Route
              path="/kasters/:program"
              component={TalentLogin}
            />
            <Route
              path="/stream/:program/:talent"
              component={TalentStream}
            />
            <Route
              path="/join/:program"
              component={Watch}
            />
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </BrowserRouter>
      </State.Provider>
    </ThemeProvider>
  );
}

App.State = State;

export default App;
