import { useState, useEffect } from "react";

interface Data {
  response: Object | any;
  error: null | boolean;
  loading: boolean;
};

const useGetJson = (url: string) => {
  const [data, setData] = useState<Data>({
    response: {status: "intial state"},
    error: false,
    loading: true
  });

  useEffect(
    () => {
      setData(d => ({ ...d, error: null, loading: true }));
      fetch(
        url,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      ).then(async r => {
        setData({
          response: await r.json(),
          error: !r.ok,
          loading: false,
        });
      }).catch(e => {
        setData({
          response: { status: "network_failure" },
          error: true,
          loading: false,
        });
      });
    },
    [url]
  );

  return data;
};

export default useGetJson;